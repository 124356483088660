<template>
	<div id="Invite">
		<!-- en-tête nom et logo entreprise  -->
		<div class="sm:flex items-center m-auto text-center sm:text-left">
			<div class="w-full sm:w-1/2 flex mt-auto">
				<vs-button
					color="#2c2c2c"
					type="flat"
					icon-pack="feather"
					icon="icon-arrow-left"
					class="inline"
					@click="$router.push({ path: '/' })"
				/>

				<h2 class="inline ml-2" style="line-height: 35px">{{ $t("Nouveau membre") }}</h2>
			</div>
			<div v-if="organization" class="w-full sm:w-1/2 flex">
				<img
					v-show="getImageUrl(organization)"
					:src="getImageUrl(organization)"
					class="rounded-full shadow-md ml-auto"
					height="50px"
					width="50px"
					style="object-fit: contain"
				/>
				<h2 class="title-sinao mt-auto mb-auto ml-4 mr-4">
					{{ organization.name }}
				</h2>
			</div>
		</div>

		<!-- Card principale avec le formulaire -->
		<vs-card class="shadow-none border mt-4">
			<div class="pb-35 text-center">
				<h4>{{ $t("Invitez un collaborateur à vous rejoindre") }}</h4>
			</div>

			<!-- cas erreur 401, on indique qu'un utilisateur possèdant l'email indiqué existe déjà dans l'App -->
			<vs-alert :active="msgErr === 401" type="warning" class="my-5">
				{{ $t("L'utilisateur que vous essayez d'inviter a déjà accès à l'application.") }}
			</vs-alert>

			<!-- cas erreur 403, on indique que l'utilisateur existe deja, on ne peut donc pas lui affecter de mot de passe -->
			<vs-alert
				:active="msgErr === 403"
				type="warning"
				class="my-5"
				:title="$t('L utilisateur que vous essayez de créer existe déjà')"
			>
				{{ $t("L'utilisateur que vous essayez de créer existe déjà ...") }}
			</vs-alert>

			<!-- cas erreur 422, on indique qu'une erreur Unprocessable entity est survenue, cela peut etre du a un probleme dans le code de l'interface ou de l'api -->
			<vs-alert :active="msgErr === 422" type="warning" class="my-5">
				{{ $t("Une erreur est survenue") }}
			</vs-alert>

			<!-- nom et prénom -->
			<div class="vx-row">
				<div class="vx-col sm:w-1/2 w-full">
					<vs-input
						v-model.trim="firstname"
						v-validate="'required'"
						name="firstname"
						color="var(--black)"
						class="w-full"
						:label-placeholder="$t('Prénom')"
					/>
					<span v-show="errors.has('firstname')" class="text-danger text-sm">{{
						errors.first("firstname")
					}}</span>
				</div>
				<div class="vx-col sm:w-1/2 w-full">
					<vs-input
						v-model.trim="lastname"
						v-validate="'required'"
						color="var(--black)"
						class="w-full mb-3"
						:label-placeholder="$t('Nom')"
						name="lastname"
					/>
					<span v-show="errors.has('lastname')" class="text-danger text-sm">{{
						errors.first("lastname")
					}}</span>
				</div>
			</div>

			<!-- mail -->
			<div class="vx-col w-full py-1">
				<vs-input
					v-model.trim="email"
					v-validate="'required|email'"
					name="email"
					color="var(--black)"
					class="w-full"
					:label-placeholder="$t('Email')"
				/>
				<span v-show="errors.has('email')" class="text-danger text-sm">{{ errors.first("email") }}</span>
			</div>

			<!-- droits -->
			<div class="vx-col w-full py-1">
				<vs-input
					v-validate="'required'"
					:value="selectedPolicy.name"
					name="selectedPolicy"
					color="var(--black)"
					class="w-full"
					:label-placeholder="$t('Choix des droits')"
					autocomplete="off"
					@focus="showPoliciesPopup = true"
					@blur="showPoliciesPopup = false"
				/>
				<span v-show="errors.has('selectedPolicy')" class="text-danger text-sm">{{
					errors.first("selectedPolicy")
				}}</span>

				<!-- popup pour choisir les nouveaux droits -->
				<vs-popup :title="$t('Choisir les nouveaux droits d un membre')" :active.sync="showPoliciesPopup">
					<!-- div + table des droits pour selectionner -->
					<div class="vx-row">
						<vs-table v-if="allPolicies" v-model="selectedPolicy" :data="allPolicies" hover-flat>
							<template slot="thead">
								<vs-th class="w-1/3"> {{ $t("Nom") }} </vs-th>
								<vs-th>{{ $t("Description") }}</vs-th>
							</template>

							<template>
								<tr v-if="disabled_Expert_Comptable" class="overlayTR">
									<td class="w-1/3">{{ $t("Expert-Comptable") }}</td>
									<td>{{ $t("Passez à une offre supérieure pour débloquer ce rôle") }}</td>
								</tr>

								<vs-tr
									v-for="(policy, index) in getFilterPolicies"
									:key="index"
									:data="policy"
									class="cursor-pointer hover:bg-sinao hover:text-black"
								>
									<vs-td class="w-1/3">
										{{ policy.name }}
									</vs-td>
									<vs-td>
										{{ policy.description }}
									</vs-td>
								</vs-tr>
							</template>
						</vs-table>
					</div>
				</vs-popup>
			</div>

			<!-- mot de passe -->
			<div class="vx-col w-full pt-3">
				<vs-checkbox v-model="sendEmail">
					{{ $t("Envoyer un mail d'invitation automatiquement") }}
				</vs-checkbox>
			</div>

			<transition name="mdp-appear">
				<div v-if="!sendEmail" class="vx-row mdp">
					<div class="vx-col w-full sm:w-1/2">
						<vs-input
							ref="password"
							v-model="password"
							v-validate="validatePassword"
							class="w-full"
							name="password"
							color="var(--black)"
							type="password"
							:label-placeholder="$t('Mot de passe')"
						/>
						<span v-show="errors.has('password')" class="text-danger text-sm">{{
							errors.first("password")
						}}</span>
					</div>
					<div class="vx-col w-full sm:w-1/2">
						<vs-input
							ref="password2"
							v-model="password2"
							v-validate="validatePassword2"
							class="w-full"
							name="password2"
							color="var(--black)"
							type="password"
							:label-placeholder="$t('Confirmer le mot de passe')"
						/>
						<span v-show="errors.has('password2')" class="text-danger text-sm">{{
							errors.first("password2")
						}}</span>
					</div>
				</div>
			</transition>

			<!-- les boutons valider et annuler -->
			<div class="vx-row pt-35">
				<div class="vx-col w-full sm:w-1/2">
					<s-button type="border" class="w-full" @click="goBack"> {{ $t("Annuler") }} </s-button>
				</div>
				<div class="vx-col w-full sm:w-1/2 mb-3">
					<s-button
						ref="submit"
						type="success"
						class="w-full vs-con-loading__container"
						:disabled="isComplete && disabled"
						@click="addUser"
					>
						{{ $t("Valider") }}
					</s-button>
				</div>
			</div>
		</vs-card>
	</div>
</template>

<script>
	import { Validator } from "vee-validate";
	import { mapGetters } from "vuex";
	import i18n from "../i18n";

	// dictionnaire des messages d'erreur inputs formulaire
	const dict = {
		custom: {
			firstname: {
				required: () => i18n.t("Veuillez entrer un prénom")
			},
			lastname: {
				required: () => i18n.t("Veuillez entrer un nom de famille")
			},
			email: {
				required: i18n.t("Veuillez entrer l'email"),
				email: i18n.t("L'email n'est pas valide")
			},
			selectedPolicy: {
				required: i18n.t("Veuillez choisir les droits")
			},
			password: {
				required: i18n.t("Veuillez entrer un mot de passe"),
				min: i18n.t("Le mot de passe doit faire plus de 8 caractères")
			},
			password2: {
				required: i18n.t("Veuillez entrer un mot de passe"),
				min: i18n.t("Le mot de passe doit faire plus de 8 caractères"),
				confirmed: i18n.t("Les mots de passe ne correspondent pas")
			}
		}
	};

	// appel du dictionnaire pour la validation formulaire
	Validator.localize("fr", dict);

	export default {
		data() {
			return {
				appId: this.$route.params.appId,
				app: null,
				allPolicies: null,
				organization: null,
				// form
				lastname: null,
				firstname: null,
				email: null,
				selectedPolicy: { name: null },
				password: null,
				password2: null,
				sendEmail: true,
				// gestion erreurs
				msgErr: 0,
				complete: false,
				showPoliciesPopup: false,
				disabled: false
			};
		},
		computed: {
			...mapGetters(["getImageUrl"]),
			isComplete() {
				if (
					this.lastname &&
					this.firstname &&
					this.email &&
					this.selectedPolicy?.id &&
					(this.sendEmail || (!this.sendEmail && this.password && this.password2))
				) {
					return false;
				}

				return true;
			},
			validatePassword() {
				return !this.sendEmail ? "required|min:8" : "";
			},
			validatePassword2() {
				return !this.sendEmail ? "required|min:8|confirmed:password" : "";
			},
			getFilterPolicies: function () {
				if (this.disabled_Expert_Comptable) {
					return this.allPolicies.filter(
						p => this.disabled_Expert_Comptable && p.name !== "Expert-Comptable"
					);
				} else {
					return this.allPolicies;
				}
			}
		},
		watch: {
			app() {
				const { organization } = this.app;

				this.organization = organization;
				this.disabled_Expert_Comptable =
					this.app.subscription.plan.rights.indexOf("!app.accounting.export.*") !== -1;
			}
		},
		async created() {
			this.app = await this.$store.dispatch("getAppByAppId", this.appId);
			this.allPolicies = await this.$store.dispatch("getPolicies", this.appId);
		},
		methods: {
			async addUser() {
				const isFormValid = await this.$validator.validateAll();
				if (isFormValid) {
					const payload = {
						appId: this.appId,
						email: encodeURIComponent(this.email),
						policyProfileId: this.selectedPolicy?.id,
						firstname: encodeURIComponent(this.firstname),
						lastname: encodeURIComponent(this.lastname)
					};

					if (!this.sendEmail) {
						payload.password = this.password;
					}

					this.disabled = true;

					this.$vs.loading({
						type: "default",
						color: "var(--white)",
						background: "var(--success)",
						container: this.$refs.submit.$el,
						scale: 0.5
					});

					this.$store
						.dispatch("sendInviteMail", payload)
						.then(async () => {
							this.app = await this.$store.dispatch("getAppByAppId", this.appId);

							let title = null;
							let text = null;

							if (this.sendEmail) {
								title = i18n.t("Invitation envoyée");
								text = i18n.t(
									"Un nouvel utilisateur a été créé, un email d'invitation vient d'être envoyé."
								);
							} else {
								title = i18n.t("Utilisateur créé");
								text =
									"Un nouvel utilisateur a été créé, vous pouvez maintenant lui communiquer ses informations de connexion.";
							}

							this.$vs.notify({
								title,
								text,
								color: "success",
								iconPack: "feather",
								icon: "icon-check",
								time: 5000
							});

							// on retourne à la page précédente
							//this.goBack();
							this.$router.push("/" + this.appId + "/members");

							this.msgErr = 200;
							this.$vs.loading.close(this.$refs.submit.$el);
						})
						.catch(err => {
							this.msgErr = err?.response?.status;
							this.$vs.loading.close(this.$refs.submit.$el);
						});
				}
			},
			goBack() {
				this.$router.go(-1);
				this.$router.query = this.ent;
			}
		}
	};
</script>

<style>
	div.vs-card--content {
		padding: 35px !important;
	}

	.pb-35 {
		padding-bottom: 35px;
	}
	.pt-35 {
		padding-top: 35px;
	}
	.mdp {
		transition: 500ms ease-in-out;
		transition-property: max-height opacity;
		overflow-y: hidden;
		height: auto;
	}
	.mdp.mdp-appear-enter {
		max-height: 0em;
		opacity: 0;
	}
	.mdp.mdp-appear-enter-to {
		max-height: 15em;
		opacity: 1;
	}
	.mdp.mdp-appear-leave {
		max-height: 15em;
		opacity: 1;
	}
	.mdp.mdp-appear-leave-to {
		max-height: 0em;
		opacity: 0;
	}

	.overlayTR {
		background: lightgray !important;
		color: var(--black);
		opacity: 0.5;
		height: fit-content;
	}
</style>
